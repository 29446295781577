<template>
  <div v-if="loaded" id="select-business-component">
    <section v-if="!hasAccountCompanies" class="get-started-container">
      <h2>
        Welcome to Stageline
      </h2>
      <p class="get-started-text">
        Your intuitive guide through every stage of your business.
        Whether you’re in the idea stage, ready to form your business, or already operating,
        the first step is the easiest—adding your company to your account.
        From there, chart your own course, your way!
      </p>
      <button
        class="primary small"
        aria-label="get started"
        @click="startNewBusiness"
      >
        Get Started
      </button>
    </section>
    <div v-else class="get-started-container">
      <h2>
        Use Stageline to manage your business.
      </h2>
      <div class="companies-list-container">
        <div class="select-company-options-container">
          <company-selector
            :companies="accountCompanies"
            @company-selected="companySelected"
          />
          <div
            v-if="displayUpdateAvailable"
            class="update-available-container"
          >
            <feather-icon class="alert-circle" type="alert-circle" />
            <p class="alert-text">
              One of your businesses has an update available
            </p>
          </div>
          <hr class="divider">
          <div class="buttons-container">
            <button
              v-if="shouldShowBusinessButton"
              class="primary large"
              aria-label="add a business"
              @click="startNewBusiness"
            >
              Add a business to my&nbsp;account
            </button>
            <router-link to="/dashpanel" class="link-home">
              Go to my account homepage
              <fa-icon icon="chevron-right" class="fa-sm pl-1 arrow" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="spinner-container">
    <ct-centered-spinner variant="primary" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SelectBusiness',
  components: {
    CompanySelector:   () => import('@/components/StagelineV2/shared/CompanySelector'),
    FeatherIcon:       () => import('@/components/shared/FeatherIcon'),
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
  },
  data() {
    return {
      loaded: true,
      loadedEntityType: false,
    }
  },
  computed: {
    ...mapGetters('account', [
      'account',
    ]),
    ...mapGetters('stageline', [
      'accountCompanies',
      'company',
      'companyStagelineRoute',
      'formationFiling',
      'formedElsewhere',
    ]),
    ...mapGetters('dashpanel', [
      'isEntityTypeDomains',
    ]),
    hasAccountCompanies() {
      return Boolean(this.accountCompanies?.length)
    },
    shouldShowBusinessButton(){
      if(!this.accountCompanies || this.accountCompanies.length === 0){
        return true
      }
      return !this.accountCompanies.every(company => company.entity_type === 'Domains')
    },
    displayUpdateAvailable() {
      return this.accountCompanies?.find(c => c?.stageline?.route?.show_alert)
    },
    missingFormationData() {
      const missingFormationDate = !this.company.config['formation_date']
      return missingFormationDate && (this.formationFiling || this.formedElsewhere)
    },
  },
  watch: {
    isEntityTypeDomains(newVal) {
      if (newVal !== null && newVal !== undefined) this.loadedEntityType = true
    },
  },
  methods: {
    ...mapActions('companies', [
      'setCurrentCompany',
      'updateCompanyConfig',
    ]),
    ...mapActions('stagelineCreateCompany', [
      'resetSlideProgress',
    ]),
    ...mapActions('creditCardProcessing', [
      'setProcessingAdStatus',
    ]),
    startNewBusiness() {
      this.resetSlideProgress()
      this.$router.push({ name: 'createCompany' })
    },
    async companySelected(selectedCompany) {
      this.loaded = false
      await this.setCurrentCompany({ id: selectedCompany.id, force: true })

      if (selectedCompany?.entity_type === 'Domains') {
        await this.$router.push({ name: 'stageline-v2-start', params: { companyId: this.company.id } })
      } else {
        if (this.missingFormationData) await this.setFormationData()
        this.hideCardProcessingAd()
        await this.showDecisionTreeOrRouteToStageline()
      }
    },
    async showDecisionTreeOrRouteToStageline() {
      const stagelineRoute       = this.companyStagelineRoute
      const lastVisitedSlide     = this.company?.stageline?.last_visited_slide
      const domesticRegistration = this.company?.domestic_registration
      const missingFileDate      = !this.company?.config?.formation_date && this.formedElsewhere

      if ((!stagelineRoute && !lastVisitedSlide) || !domesticRegistration || missingFileDate) {
        await this.$router.push({ name: 'stageline-v2-decision-tree', params: { companyId: this.company.id } })
        this.loaded = true
      } else if (stagelineRoute) {
        await this.$router.push({ name: 'stageline-v2-start', params: { companyId: this.company.id } })
      } else {
        await this.$router.push({ name: 'overview', params: { companyId: this.company.id } })
      }
    },
    hideCardProcessingAd() {
      this.setProcessingAdStatus({
        ownerID: this.account.id, status: false, showInFuture: true,
      })
    },
    async setFormationData() {
      const config = {}
      config['formation_date'] = this.formationFiling ?
        await this.getFormationDateFromFormationFiling() :
        this.getFormationDateFromCompanyRegistration()

      if (config['formation_date']) await this.setCompanyConfig(config)
    },
    getFormationDateFromFormationFiling() {
      if (this.formationFiling['status'] === 'completed' && !!this.formationFiling['registration_id']) {
        return this.formationFiling?.company?.created_at.split('T')[0]
      }

      return null
    },
    getFormationDateFromCompanyRegistration() {
      const epochDate = this.company?.domestic_registration?.file_date

      if (typeof(epochDate) === 'undefined' || epochDate === null) return null

      const date = new Date(epochDate * 1000)
      const yyyy = date.getUTCFullYear()
      const mm   = ((date.getUTCMonth() + 1) < 10) ? `0${date.getUTCMonth() + 1}` : date.getUTCMonth() + 1
      const dd   = (date.getUTCDate() < 10) ? `0${date.getUTCDate()}` : date.getUTCDate()

      return `${yyyy}-${mm}-${dd}`
    },
    async setCompanyConfig(config) {
      const companyId = this.company.id
      config['formed_elsewhere'] = true

      await this.updateCompanyConfig({ companyId, config })
    },
  },
}
</script>

<style lang="scss" scoped>
// Palette
$black1: #231F20;
$gray2-light3: #E9E9E9;
$teal1: $ct-ui-color-10;
$teal2: $ct-ui-color-26;

// Typography
// Typography: Mixin
@mixin typography($font-size: 1.0em, $font-weight: $ct-ui-font-weight-6, $color: $black1) {
  font-family: "Open Sans", "Roboto", sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  color: $color;
}

// Typography: Fonts
h2, p {
  margin: 0;
}

h2 {
  font-weight: $ct-ui-font-weight-6;
  color: $black1;
  margin-bottom: 0.2em;
  padding: 0;
  text-align: center;
  line-height: 1.2;
}

p {
  @include typography();
}

a {
  @include typography(
    $color: $teal1
  );

  &:hover {
    color: $teal2;
    text-decoration: underline;
  }
}

// Buttons
// Buttons: Mixins
@mixin btn-hover($hover-color: $teal2, $color: white) {
  border-color: $hover-color;
  background: $hover-color;
  color: $color;
}

@mixin btn-core($padding, $hover-color) {
  @include typography($color: white);
  width: 100%;
  border: 0.0625em solid $teal1;
  border-radius: 0.3125em;
  padding: $padding;
  margin: 0;
  outline: 0;
  line-height: 1.1;

  &:hover {
    @include btn-hover();
  }
}

@mixin btn-default($primary-color, $hover-color, $padding: 0.6em 1.875em) {
  background: $primary-color;
  color: white;
  border-color: $primary-color;
  @include btn-core($padding, $hover-color);
}

// Buttons: Types
button.primary {
  @include btn-default(
    $primary-color: $teal1,
    $hover-color: $teal2
  );

  &.small {
    max-width: 170px;
  }

  &.large {
    max-width: 310px;
  }
}

// Normalize
* {
  margin: 0;
  padding: 0;
}

// Component
#select-business-component {

  .get-started-container {
    width: 100%;
    max-width: 39.375em;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    h2 {
      padding-bottom: 0.2em;
      text-align: center;
    }

    p {

      &.get-started-text {
        padding-bottom: 2.0em;
      }
    }

    .companies-list-container {
      width: 100%;
      padding-top: 1.0em;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;

      .select-company-options-container {
        width: 80%;
        padding-top: 0.5em;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;

        .update-available-container {
          padding-top: 2.0em;
          display: flex;
          align-content: center;

          .alert-circle {
            display: flex;
            margin-right: 0.5em;
          }

          .alert-text {
            @include typography(
              $font-weight: $ct-ui-font-weight-7
            );
          }
        }
      }

      .divider {
        align-self: center;
        height: 1px;
        width: 81%;
        margin-top: 3.5em;
        margin-bottom: 3.5em;
        color: $gray2-light3;
      }

      .buttons-container {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        row-gap: 2.75em;
      }
    }
  }
}

.spinner-container {
  height: 100%;
  align-items: center;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  #select-business-component {

    .get-started-container {

      .companies-list-container {

        .select-company-options-container {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  #select-business-component {
    padding: 0;

    .get-started-container {
      max-width: unset;

      h2 {
        @include typography(
          $font-size: 2.125em,
          $font-weight: $ct-ui-font-weight-7
        );
        padding-top: 0;
      }

      .small {
        max-width: unset;
      }

      .companies-list-container {

        .select-company-options-container {
          width: 100%;
        }
      }
    }
  }
}
</style>
